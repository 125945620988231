import React from "react";
import project1 from "../assets/img/bx-coach-icon.jpg";
import project2 from "../assets/img/logo.png";
import project3 from "../assets/img/logo-full.png";
import heardLogo from "../assets/img/heard-icon.png";
import mattLogo from "../assets/img/2540.png";
import coverage from "../assets/img/coverage.jpg";
import header from "../assets/img/tgreen_header.png";
// import coach from "../assets/img/logo.png";

export const Home = () => {
  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    // if (
    //   document.body.scrollTop > 80 ||
    //   document.documentElement.scrollTop > 80
    // ) {
    //   document.querySelector(".navbar-brand").style.background = "white";
    //   document.querySelector(".navbar-brand").style.color = "#00b3ad";
    // } else {
    //   document.querySelector(".navbar-brand").style.background = "transparent";
    //   document.querySelector(".navbar-brand").style.color = "white";
    // }
  };
  return (
    <div>
      <img className="w-100" alt={"header"} src={header} />

      {/*  <section className="banner">
         <div className="navbar-brand px-5 py-4 w-100">T-GREEN SOLUTIONS</div>
        <div className="container">
          <div className="header-content">
         <div className="text-center">
              <h3>Green Consulting and Staffing</h3>
              <p className="my-4">The business of finding solutions.</p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="py-space container">
        <div className="row">
          <div className="col-lg-3 col-12 my-4">
            <div
              className="project-container text-center"
              data-toggle="modal"
              data-target="#coverageModal"
            >
              <img src={coverage} alt="Coverage logo" className="w-100" />

              <div className="subtitle py-4">Coverage - Temp Agency</div>
            </div>
          </div>
          <div className="col-lg-3 col-12 my-4">
            <div
              className="project-container text-center"
              data-toggle="modal"
              data-target="#coachModal"
            >
              <img src={project1} alt="BxCoach logo" className="w-100" />
              <div className="subtitle py-4">BxCoach</div>
            </div>
          </div>
          <div className="col-lg-3 col-12 my-4">
            <div
              className="project-container text-center"
              data-toggle="modal"
              data-target="#benevolenceModal"
            >
              <img src={project2} alt="Benelovence logo" className="w-100" />

              <div className="subtitle py-4">Benevolence</div>
            </div>
          </div>
          <div className="col-lg-3 col-12 my-4">
            <div
              className="project-container text-center"
              data-toggle="modal"
              data-target="#reboundingModal"
            >
              <img src={project3} alt="Rebounding Logo" className="w-100" />

              <div className="subtitle py-4">Rebounding</div>
            </div>
          </div>

          {/* Heard. */}
          <div className="col-lg-3 col-12 my-4">
            <div
              className="project-container text-center"
              data-toggle="modal"
              data-target="#heardModal"
            >
              <img src={heardLogo} alt="Heard logo" className="w-100" />

              <div className="subtitle py-4">Heard.</div>
            </div>
          </div>

          {/* 2540. */}
          <div className="col-lg-3 col-12 my-4">
            <div
              className="project-container text-center"
              data-toggle="modal"
              data-target="#mattdModal"
            >
              <img src={mattLogo} alt="2540 logo" className="w-100" />

              <div className="subtitle py-4">2540</div>
            </div>
          </div>
        </div>
      </section>
      <footer className="container mt-auto mb-3">
        <hr />
        <div className="d-flex justify-content-between flex-wrap align-items-center">
          <div className="mt-3">
            <span className="mr-4"> T-Green Solutions</span>
            <a href="mailto:admin@tgreen.solutions">Contact Us</a>
          </div>
          <div className="mt-3">
            © 2020 Green Consulting and Staffing, LLC. All Rights Reserved.
          </div>
        </div>
      </footer>

      {/* <!-- Modal Coverage --> */}
      <div
        class="modal fade"
        id="coverageModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="coverageModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div className="modal-body text-center px-4">
              <div className="text-right click" data-dismiss="modal">
                <i className="fa fa-close"></i>
              </div>
              <div className="project-icon mx-auto py-4">
                <img src={coverage} alt="Coverage icon" className="w-100" />
              </div>
              <p className="title mb-0">COVERAGE - TEMP AGENCY</p>

              <div className="featured mb-2">
                <a
                  target="_blanc"
                  href="https://www.facebook.com/greenconsultingandstaffing/"
                  className="ml-3"
                >
                  Facebook
                </a>
                {/* <a target="_blanc" href="" className="ml-3">
                    Instagram
                  </a> */}
              </div>

              <div className="download mt-3">
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://apps.apple.com/us/app/coverage-temp-agency/id1510156792?ls=1"
                >
                  <i className="fa fa-apple"></i>
                </a>
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://play.google.com/store/apps/details?id=com.hollainc.coverage&hl=en_SG"
                >
                  <i className="fa fa-android"></i>
                </a>
              </div>

              <div className="my-3 project-content font20">
                COVERAGE - Temp Agency is the first Behavioral Health Staffing
                Agency in the palm of your hand. We promote quality
                professionals and offer only healthy work environments. Our
                user-friendly app makes earning income easy. Employees interact
                directly with Employers in rate negotiations and shifts worked.
              </div>

              <div class="iframe-responsive project-img">
                <iframe
                  title="Coverage Explainer"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/KO-1toITnCc"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>

              <div class="d-flex justify-content-center my-4">
                <a
                  className="ml-3"
                  target="_blanc"
                  href="mailto:coverage@tgreen.solutions"
                >
                  <button class="btn btn-green">Contact us</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal BxCoach--> */}
      <div
        class="modal fade"
        id="coachModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="coachModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div className="modal-body text-center px-4">
              <div className="text-right click" data-dismiss="modal">
                <i className="fa fa-close"></i>
              </div>
              <div className="project-icon mx-auto py-4">
                <img src={project1} alt="BxCoach logo" className="w-100" />
              </div>
              <p className="title mb-0">BxCoach</p>
              <div className="featured mb-2">
                <a target="_blanc" href="https://bxcoach.team" className="ml-3">
                  Website
                </a>
                <a
                  target="_blanc"
                  href="https://www.facebook.com/greenconsultingandstaffing/"
                  className="ml-3"
                >
                  Facebook
                </a>
              </div>

              <div className="download mt-3">
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://apps.apple.com/us/app/bxcoach/id1535860066"
                >
                  <i className="fa fa-apple"></i>
                </a>
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://play.google.com/store/apps/details?id=com.hollainc.bxcoach"
                >
                  <i className="fa fa-android"></i>
                </a>
              </div>

              <div className="my-3 project-content font20">
                BxCoach is the first 24/7 Behavior Coaching Agency in the palm
                of your hand. Our Behavior Coaches help each child learn skills
                necessary to regulate their emotions, modify behaviors, and
                achieve their goals. Crisis Support is available 24/7 within the
                App to help reinforce skills learned during difficult times.
                Your Coach is with you wherever you go, whenever you need them;
                helping you develop skills necessary to progress towards
                independence.
              </div>
              <div className="download">
                {/* <a
                  className="ml-3"
                  target="_blanc"
                  href="https://benevolence.care/"
                >
                  <i className="fa fa-external-link"></i>
                </a> */}
              </div>
            </div>
            <div class="d-flex justify-content-center my-4">
              <a
                className="ml-3"
                target="_blanc"
                href="mailto:BxCoach@tgreen.solutions"
              >
                <button class="btn btn-blue">Contact us</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal Rebounding--> */}
      <div
        class="modal fade"
        id="reboundingModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="reboundingModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div className="modal-body text-center px-4">
              <div className="text-right click" data-dismiss="modal">
                <i className="fa fa-close"></i>
              </div>
              <div className="project-icon mx-auto py-4">
                <img src={project3} alt="Rebounding Icon" className="w-100" />
              </div>
              <p className="title mb-0">REBOUNDING</p>
              {/* <div className="featured mb-2">
                <span className="ml-3 text-green">Coming soon...</span>

             
              </div> */}

              <div className="featured mb-2">
                <a
                  target="_blanc"
                  href="https://rebounding.biz"
                  className="ml-3"
                >
                  Website
                </a>
                <a
                  target="_blanc"
                  href="https://www.facebook.com/greenconsultingandstaffing/"
                  className="ml-3"
                >
                  Facebook
                </a>
                {/* <a target="_blanc" href="" className="ml-3">
                    Instagram
                  </a> */}
              </div>

              <div className="download mt-3">
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://apps.apple.com/us/app/rebounding/id1610084135"
                >
                  <i className="fa fa-apple"></i>
                </a>
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://play.google.com/store/apps/details?id=com.hollainc.rebounding"
                >
                  <i className="fa fa-android"></i>
                </a>
              </div>

              {/* <div className="download">
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://rebounding.biz/"
                >
                  <i className="fa fa-external-link"></i>
                </a>
              </div> */}
              <div className="my-3 project-content font20">
                We help you navigate the unexpected trajectory of a break-up.
                Our Coaches are qualified to support you to victory over a
                failed relationship or broken marriage. We focus on helping you
                develop and explore the skills necessary to get back in the
                game.
              </div>
            </div>
            <div class="d-flex justify-content-center my-4">
              <a
                className="ml-3"
                target="_blanc"
                href="mailto:rebounding@tgreen.solutions"
              >
                <button class="btn btn-blue">Contact us</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal Benevolence--> */}
      <div
        class="modal fade"
        id="benevolenceModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="benevolenceModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div className="modal-body text-center px-4">
              <div className="text-right click" data-dismiss="modal">
                <i className="fa fa-close"></i>
              </div>
              <div className="project-icon mx-auto py-4">
                <img src={project2} alt="Benevolence" className="w-100" />
              </div>
              <p className="title mb-0">BENEVOLENCE</p>

              <div className="featured mb-2">
                <a
                  target="_blanc"
                  href="https://benevolence.care/"
                  className="ml-3"
                >
                  Website
                </a>
              </div>
              <div className="download">
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://benevolence.care/"
                >
                  <i className="fa fa-external-link"></i>
                </a>
              </div>
              <div className="my-3 project-content font20">
                Do all the good you can, by all the means you can, in all the
                ways you can, in all the places you can, at all the times you
                can, to all the people you can, as long as ever you can.
              </div>
            </div>
            <div class="d-flex justify-content-center my-4">
              <a target="_blanc" href="https://benevolence.care/contact">
                <button class="btn btn-blue">Contact us</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal Heard. --> */}
      <div
        class="modal fade"
        id="heardModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="heardModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div className="modal-body text-center px-4">
              <div className="text-right click" data-dismiss="modal">
                <i className="fa fa-close"></i>
              </div>
              <div className="project-icon mx-auto py-4">
                <img src={heardLogo} alt="Heard Logo" className="w-100" />
              </div>
              <p className="title mb-0">Heard.</p>

              {/* <div className="featured mb-2">
                <a
                  target="_blanc"
                  href="https://www.facebook.com/greenconsultingandstaffing/"
                  className="ml-3"
                >
                  Facebook
                </a> */}
              {/* <a target="_blanc" href="" className="ml-3">
                    Instagram
                  </a> */}
              {/* </div> */}

              <div className="download mt-3">
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://apps.apple.com/us/app/heard/id1544122276?ign-mpt=uo%3D2"
                >
                  <i className="fa fa-apple"></i>
                </a>
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://play.google.com/store/apps/details?id=io.holladev.heard"
                >
                  <i className="fa fa-android"></i>
                </a>
              </div>

              <div className="my-3 project-content font20">
                Heard. is the App that gives you the opportunity to text
                whatever you want or need to say, whenever or wherever you need
                to say it. The best part about it is, right after you send it -
                it's gone. No one can look at it later. No one will be offended
                or concerned. You have a safe place to release. Whatever you
                want to release. Whenever you want to release. Spouse upset you,
                don't say something you'll regret. Text it to us. Want to say
                something you shouldn't say in a meeting? Text it to us. Upset
                at the kids? Text it to us. Not in a place you can pray, any
                kind of prayer. Text it to us. There's nothing you text that
                will cause damage. It's simply Spoken. And after you speak it,
                know you've been Heard.
              </div>

              <div class="d-flex justify-content-center my-4">
                <a
                  className="ml-3"
                  target="_blanc"
                  href="mailto:heard@tgreen.solutions"
                >
                  <button class="btn btn-green">Contact us</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal 2540 --> */}
      <div
        class="modal fade"
        id="mattdModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mattdModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div className="modal-body text-center px-4">
              <div className="text-right click" data-dismiss="modal">
                <i className="fa fa-close"></i>
              </div>
              <div className="project-icon mx-auto py-4">
                <img src={mattLogo} alt="2450 Logo" className="w-100" />
              </div>
              <p className="title mb-0">2540</p>

              {/* <div className="download mt-3">
                <a
                  className="ml-3"
                  target="_blanc"
                  href="https://2540.us"
                >
                  <i className="fa fa-apple"></i>
                </a>
              </div> */}

              <div className="my-3 project-content font20">
                Providing accessible housing options at your fingertips.
              </div>

              <div class="d-flex justify-content-center my-4">
                <a className="ml-3" target="_blanc" href="https://2540.us">
                  <button class="btn btn-green">Visit Website</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
